/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ReactPlayer from 'react-player/lazy';
import ResponsiveDialog from '@fathomtech/nualang-ui-components/dist/Dialogs/ResponsiveDialog';
import Navbar from '../Navbar/Navbar';
import Section from '../Section';
import config from '../../config';

const EducatorHeroSVG = ({
  className,
  playButtonClassName,
  onClick,
}) => (
  <svg className={className} onClick={onClick} width="1081" height="575" viewBox="0 0 1081 575" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M592 206L529 242.373L529 169.627L592 206Z" fill="white"/>
  <g clipPath="url(#clip0)">
  <path d="M1036 154C1060.85 154 1081 133.853 1081 109C1081 84.1472 1060.85 64 1036 64C1011.15 64 991 84.1472 991 109C991 133.853 1011.15 154 1036 154Z" fill="#F6DDB7"/>
  <path d="M1036 64C1027.1 64 1018.4 66.6392 1011 71.5839C1003.6 76.5285 997.831 83.5566 994.425 91.7792C991.019 100.002 990.128 109.05 991.865 117.779C993.601 126.508 997.887 134.526 1004.18 140.82C1010.47 147.113 1018.49 151.399 1027.22 153.135C1035.95 154.872 1045 153.981 1053.22 150.575C1061.44 147.169 1068.47 141.401 1073.42 134.001C1078.36 126.6 1081 117.9 1081 109C1081 97.0653 1076.26 85.6193 1067.82 77.1802C1059.38 68.7411 1047.93 64 1036 64V64Z" fill="#F6DDB7"/>
  <path d="M1036 70.1362C1043.69 70.1362 1051.2 72.4155 1057.59 76.6859C1063.98 80.9563 1068.96 87.026 1071.91 94.1274C1074.85 101.229 1075.62 109.043 1074.12 116.582C1072.62 124.121 1068.92 131.045 1063.48 136.481C1058.05 141.916 1051.12 145.617 1043.58 147.117C1036.04 148.616 1028.23 147.847 1021.13 144.905C1014.03 141.964 1007.96 136.982 1003.69 130.591C999.416 124.2 997.136 116.686 997.136 109C997.136 98.6926 1001.23 88.8075 1008.52 81.5191C1015.81 74.2308 1025.69 70.1362 1036 70.1362Z" fill="#8F01AA"/>
  <path d="M1050.68 103.124C1050.48 102.621 1050.09 102.219 1049.59 102.006C1049.09 101.794 1048.53 101.787 1048.02 101.989L1040.6 104.955C1039.91 104.169 1039.03 103.572 1038.05 103.222V83.9432C1038.05 83.4007 1037.83 82.8804 1037.45 82.4968C1037.06 82.1132 1036.54 81.8977 1036 81.8977C1035.46 81.8977 1034.94 82.1132 1034.55 82.4968C1034.17 82.8804 1033.95 83.4007 1033.95 83.9432V103.222C1032.89 103.597 1031.96 104.256 1031.24 105.128C1030.53 106 1030.08 107.051 1029.92 108.166C1029.77 109.28 1029.92 110.416 1030.37 111.448C1030.82 112.481 1031.54 113.37 1032.46 114.019C1033.38 114.668 1034.46 115.052 1035.58 115.129C1036.7 115.206 1037.83 114.972 1038.83 114.454C1039.82 113.936 1040.66 113.153 1041.25 112.191C1041.83 111.229 1042.14 110.125 1042.14 109V108.755L1049.56 105.783C1050.06 105.579 1050.46 105.185 1050.67 104.686C1050.88 104.188 1050.88 103.626 1050.68 103.124Z" fill="#F6DDB7"/>
  </g>
  <g clipPath="url(#clip1)">
  <path d="M938.5 0H204.5H194.5V10V436V446H204.5H938.5H948.5V436V10V0H938.5Z" fill="#F6DDB7"/>
  <path d="M938.5 10H204.5V436H938.5V10Z" fill="#8F01AA"/>
  <path d="M956 458.57H187C183.022 458.57 179.206 456.989 176.393 454.176C173.58 451.363 172 447.548 172 443.57C172 439.592 173.58 435.776 176.393 432.963C179.206 430.15 183.022 428.57 187 428.57H956C959.978 428.57 963.794 430.15 966.607 432.963C969.42 435.776 971 439.592 971 443.57C971 447.548 969.42 451.363 966.607 454.176C963.794 456.989 959.978 458.57 956 458.57Z" fill="#F6DDB7"/>
  <path
    d="M547.5 244.265V182L603.426 213.133L547.5 244.265Z"
    fill="white"
    className={`animate__animated animate__bounce animate__delay-1s animate__repeat-1 ${playButtonClassName}`}
  />
  <path d="M543.198 277.213H535.926V294.013H539.814V289.381H543.198C547.686 289.381 550.494 287.053 550.494 283.309C550.494 279.541 547.686 277.213 543.198 277.213ZM542.982 286.213H539.814V280.381H542.982C545.358 280.381 546.558 281.461 546.558 283.309C546.558 285.133 545.358 286.213 542.982 286.213ZM555.67 294.013H567.982V290.845H559.558V277.213H555.67V294.013ZM585.247 294.013H589.327L581.815 277.213H577.975L570.487 294.013H574.471L575.959 290.413H583.759L585.247 294.013ZM577.207 287.461L579.871 281.029L582.535 287.461H577.207ZM607.031 277.213H603.215L598.727 284.677L594.239 277.213H590.111L596.615 288.013V294.013H600.503V288.061L607.031 277.213Z" fill="white"/>
  </g>
  <path d="M216.571 295.599C215.97 294.063 214.786 292.828 213.278 292.163C211.77 291.498 210.061 291.458 208.523 292.05L192.627 298.204C191.093 298.806 189.859 299.99 189.195 301.5C188.531 303.009 188.49 304.72 189.082 306.259C189.683 307.794 190.867 309.029 192.374 309.694C193.882 310.359 195.591 310.4 197.129 309.808L213.032 303.654C214.565 303.051 215.797 301.865 216.46 300.356C217.123 298.847 217.163 297.137 216.571 295.599Z" fill="#F2A183"/>
  <path d="M51.0527 346.916L17.0641 566.428L40.0161 569.988L74.0047 350.477L51.0527 346.916Z" fill="#F6B089"/>
  <path d="M105.974 569.158C105.68 470.414 72.2551 363.218 71.9204 362.144L94.0671 355.143C95.488 359.656 128.906 466.715 129.2 569.09L105.974 569.158Z" fill="#F6B089"/>
  <path d="M80.1792 574.02C79.9484 569.423 78.389 564.991 75.6904 561.264C72.9918 557.537 69.27 554.675 64.9772 553.024C60.6843 551.374 56.0051 551.007 51.5078 551.968C47.0105 552.929 42.8887 555.176 39.6429 558.437H16.0617V574.02H80.1792Z" fill="#232323"/>
  <path d="M166.703 574.02C166.682 569.081 165.19 564.262 162.419 560.176C159.647 556.091 155.722 552.925 151.144 551.083C146.567 549.242 141.544 548.808 136.719 549.838C131.894 550.867 127.485 553.314 124.056 556.864H100.83V574.02H166.703Z" fill="#232323"/>
  <path d="M66.8449 396.771L72.1117 362.766C71.9956 362.397 71.9341 362.185 71.9204 362.144L72.221 362.049L74.0108 350.493L51.058 346.93L44.4249 389.824L66.8449 396.771Z" fill="#E87D6A"/>
  <path d="M81.1083 395.431L103.856 390.385C98.6234 369.769 94.5315 356.661 94.0534 355.143L72.1936 362.049L72.0843 362.767C72.9655 365.55 76.5314 377.482 81.1083 395.431Z" fill="#E87D6A"/>
  <path d="M167.639 323.265C149.195 323.265 133.606 318.854 121.105 310.047C96.014 292.385 93.6094 263.667 93.4933 262.484L114.608 260.891V260.829C114.608 261.027 116.583 281.083 133.531 292.871C149.359 303.88 173.418 305.042 205.033 296.338L210.648 316.769C194.927 321.104 180.591 323.269 167.639 323.265Z" fill="#F6B089"/>
  <path d="M124.104 269.096C124.791 264.47 124.119 259.745 122.168 255.495C120.218 251.246 117.074 247.656 113.121 245.165C109.167 242.674 104.574 241.388 99.903 241.465C95.2317 241.542 90.6839 242.979 86.8143 245.599C82.9447 248.219 79.9208 251.91 78.1114 256.222C76.302 260.533 75.7854 265.278 76.6247 269.879C77.4639 274.479 79.6227 278.735 82.8376 282.129C86.0526 285.522 90.1846 287.905 94.7297 288.987L124.104 269.096Z" fill="#52107C"/>
  <path d="M47.5809 271.729H89.3535C93.0316 271.729 96.6736 272.454 100.072 273.863C103.47 275.271 106.557 277.336 109.158 279.94C111.759 282.543 113.822 285.634 115.229 289.035C116.637 292.436 117.361 296.082 117.361 299.763V379.588H19.6003V299.736C19.6003 296.054 20.3248 292.409 21.7323 289.008C23.1398 285.606 25.2029 282.516 27.8036 279.912C30.4044 277.309 33.492 275.244 36.89 273.835C40.2881 272.426 43.9301 271.701 47.6082 271.701L47.5809 271.729Z" fill="#52107C"/>
  <path d="M67.4801 229.272H73.3344C80.7626 229.272 87.8865 232.226 93.1389 237.484C98.3914 242.741 101.342 249.872 101.342 257.307V285.342H39.4996V257.307C39.4996 249.877 42.4466 242.75 47.6932 237.493C52.9398 232.237 60.0567 229.28 67.4801 229.272Z" fill="#7B04A4"/>
  <path d="M107.276 160.562L36.7202 157.219C18.1941 156.341 2.46478 170.663 1.58787 189.207C0.710961 207.751 15.0185 223.495 33.5446 224.373L104.1 227.716C122.626 228.594 138.355 214.272 139.232 195.728C140.109 177.184 125.802 161.44 107.276 160.562Z" fill="#001B2B"/>
  <path d="M139.221 195.733C138.814 204.358 135.106 212.495 128.866 218.457C122.626 224.419 114.334 227.748 105.707 227.755C105.175 227.755 104.635 227.741 104.088 227.714L33.536 224.37C28.8102 224.142 24.1858 222.918 19.9652 220.778C15.7447 218.637 12.0231 215.629 9.04403 211.95C6.06492 208.271 3.89539 204.004 2.67739 199.428C1.45938 194.852 1.22033 190.07 1.97589 185.395C3.22184 192.994 7.03569 199.935 12.78 205.057C18.5244 210.18 25.8512 213.174 33.536 213.539L104.088 216.883C104.628 216.883 105.175 216.924 105.707 216.924C113.674 216.917 121.378 214.076 127.445 208.908C133.511 203.74 137.545 196.581 138.825 188.711C139.201 191.031 139.334 193.385 139.221 195.733Z" fill="black"/>
  <path d="M85.3631 214.422C85.6844 208.944 81.5086 204.243 76.0362 203.921C70.5638 203.6 65.8671 207.779 65.5458 213.257L64.3039 234.431C63.9826 239.909 68.1584 244.61 73.6308 244.932C79.1032 245.254 83.7999 241.074 84.1211 235.596L85.3631 214.422Z" fill="#E87D6A"/>
  <path d="M110.23 188.766C113.946 188.766 116.958 185.75 116.958 182.031C116.958 178.311 113.946 175.295 110.23 175.295C106.514 175.295 103.501 178.311 103.501 182.031C103.501 185.75 106.514 188.766 110.23 188.766Z" fill="#E87D6A"/>
  <path d="M42.7579 186.215C46.4741 186.215 49.4867 183.199 49.4867 179.479C49.4867 175.76 46.4741 172.744 42.7579 172.744C39.0418 172.744 36.0292 175.76 36.0292 179.479C36.0292 183.199 39.0418 186.215 42.7579 186.215Z" fill="#E87D6A"/>
  <path d="M58.1334 144.004L96.6849 145.372C101.03 145.526 105.137 147.402 108.1 150.587C111.064 153.771 112.643 158.004 112.489 162.354L111.668 185.526C111.329 195.097 107.206 204.141 100.205 210.669C93.204 217.197 83.8992 220.674 74.3374 220.335C65.3858 220.017 56.9268 216.153 50.8215 209.592C44.7161 203.032 41.4643 194.313 41.7816 185.352L42.7382 158.339C42.8064 156.386 43.2591 154.464 44.0702 152.686C44.8813 150.907 46.0349 149.307 47.4649 147.975C48.8949 146.643 50.5734 145.607 52.404 144.926C54.2347 144.245 56.1817 143.931 58.1334 144.004Z" fill="#F6B089"/>
  <path d="M62.8417 168.204H62.8349C61.3409 168.204 60.1298 169.416 60.1298 170.912V175.432C60.1298 176.927 61.3409 178.139 62.8349 178.139H62.8417C64.3357 178.139 65.5469 176.927 65.5469 175.432V170.912C65.5469 169.416 64.3357 168.204 62.8417 168.204Z" fill="#070D13"/>
  <path d="M101.54 169.825H101.534C100.04 169.825 98.8284 171.037 98.8284 172.532V177.052C98.8284 178.548 100.04 179.76 101.534 179.76H101.54C103.034 179.76 104.246 178.548 104.246 177.052V172.532C104.246 171.037 103.034 169.825 101.54 169.825Z" fill="#070D13"/>
  <path d="M66.7697 166.413C67.6865 166.413 68.4297 165.669 68.4297 164.751C68.4297 163.833 67.6865 163.089 66.7697 163.089H60.164C59.2472 163.089 58.504 163.833 58.504 164.751C58.504 165.669 59.2472 166.413 60.164 166.413H66.7697Z" fill="#E87D6A"/>
  <path d="M105.516 167.999C106.433 167.999 107.176 167.256 107.176 166.338C107.176 165.42 106.433 164.676 105.516 164.676H98.9102C97.9935 164.676 97.2503 165.42 97.2503 166.338C97.2503 167.256 97.9935 167.999 98.9102 167.999H105.516Z" fill="#E87D6A"/>
  <path d="M42.7717 158.365L42.601 163.117L112.416 165.34L112.518 162.42C112.55 161.529 112.508 160.637 112.395 159.753L42.8195 157.51C42.7991 157.784 42.7786 158.064 42.7717 158.365Z" fill="#E87D6A"/>
  <path d="M42.7717 158.365L42.601 163.117L112.416 165.34L112.518 162.42C112.55 161.529 112.508 160.637 112.395 159.753L42.8195 157.51C42.7991 157.784 42.7786 158.064 42.7717 158.365Z" fill="#E87D6A"/>
  <path d="M66.7697 163.609C67.6865 163.609 68.4297 162.865 68.4297 161.947C68.4297 161.03 67.6865 160.286 66.7697 160.286H60.164C59.2472 160.286 58.504 161.03 58.504 161.947C58.504 162.865 59.2472 163.609 60.164 163.609H66.7697Z" fill="#070D13"/>
  <path d="M105.516 165.196C106.433 165.196 107.176 164.452 107.176 163.534C107.176 162.616 106.433 161.873 105.516 161.873H98.9102C97.9935 161.873 97.2503 162.616 97.2503 163.534C97.2503 164.452 97.9935 165.196 98.9102 165.196H105.516Z" fill="#070D13"/>
  <path d="M78.9223 118.022C74.337 117.86 69.7649 118.604 65.467 120.211C61.1691 121.819 57.2297 124.258 53.8739 127.39C50.5181 130.522 47.8115 134.285 45.9089 138.464C44.0063 142.643 42.9449 147.156 42.7853 151.746L42.5804 157.517C42.5804 157.715 42.5804 157.913 42.5804 158.105L76.0532 159.295V147.075L89.5857 159.773L112.327 160.58C112.327 160.382 112.327 160.19 112.327 159.992L112.532 154.214C112.866 144.955 109.513 135.941 103.211 129.154C96.9089 122.368 88.1726 118.364 78.9223 118.022Z" fill="#001B2B"/>
  <path d="M90 160H76V147L90 160Z" fill="#E87D6A"/>
  <path d="M94.0738 187.768C94.0663 188.111 94.0412 188.453 93.9987 188.793C93.582 192.616 91.7922 195.747 87.1675 197.087C85.2925 197.588 83.3557 197.819 81.4157 197.771C79.4042 197.831 77.3977 197.539 75.4862 196.91C71.8178 195.59 70.5404 192.807 70.1989 189.388C70.1442 188.862 70.1169 188.315 70.1032 187.754L94.0738 187.768Z" fill="#831221"/>
  <path d="M87.1676 197.067C85.2926 197.567 83.3558 197.798 81.4157 197.75C79.4043 197.81 77.3978 197.518 75.4863 196.889C76.2088 196.038 77.11 195.357 78.1257 194.894C79.1414 194.432 80.2466 194.2 81.3623 194.214C82.478 194.228 83.5768 194.489 84.5803 194.977C85.5838 195.466 86.4672 196.17 87.1676 197.039V197.067Z" fill="#EA3559"/>
  <path d="M70.1032 187.767C70.1032 188.328 70.1442 188.875 70.1989 189.401C73.6688 191.424 77.6264 192.455 81.6411 192.383C86.539 192.383 90.9452 191.015 93.9987 188.793C94.0412 188.452 94.0663 188.11 94.0738 187.767H70.1032Z" fill="white"/>
  <path d="M50.2723 278.539C54.2544 276.002 57.4034 272.348 59.3276 268.034C61.2517 263.719 61.8661 258.933 61.0942 254.272C60.3224 249.61 58.1985 245.279 54.9866 241.816C51.7748 238.354 47.6167 235.912 43.0298 234.797C38.4428 233.681 33.6293 233.94 29.1881 235.541C24.7469 237.142 20.8739 240.014 18.051 243.802C15.228 247.589 13.5797 252.123 13.3111 256.84C13.0424 261.558 14.1653 266.25 16.5399 270.334L50.2723 278.539Z" fill="#52107C"/>
  <path d="M30.2501 412.306C31.0459 412.115 31.7963 411.769 32.4584 411.287C33.1205 410.806 33.6814 410.199 34.109 409.5C34.5366 408.802 34.8224 408.026 34.9503 407.217C35.0782 406.407 35.0456 405.581 34.8543 404.785L30.9059 388.176C30.7157 387.378 30.3704 386.626 29.8898 385.963C29.4091 385.299 28.8025 384.737 28.1046 384.309C27.4067 383.88 26.6312 383.593 25.8225 383.465C25.0138 383.336 24.1878 383.369 23.3916 383.56C22.5958 383.751 21.8454 384.097 21.1832 384.579C20.5211 385.06 19.9603 385.668 19.5327 386.366C19.1051 387.065 18.8192 387.84 18.6913 388.65C18.5635 389.459 18.5961 390.285 18.7874 391.082L22.7358 407.691C22.9259 408.488 23.2712 409.24 23.7519 409.903C24.2326 410.567 24.8392 411.129 25.5371 411.558C26.235 411.986 27.0105 412.273 27.8191 412.401C28.6278 412.53 29.4539 412.497 30.2501 412.306Z" fill="#F2A183"/>
  <path d="M28.7132 426.406C30.3198 426.018 31.7072 425.009 32.5716 423.599C33.4359 422.189 33.7066 420.493 33.3242 418.884L29.3963 402.296C29.0091 400.688 28.0004 399.299 26.5916 398.435C25.1827 397.571 23.4889 397.302 21.882 397.687C20.2745 398.073 18.8858 399.082 18.0212 400.492C17.1566 401.903 16.8868 403.599 17.271 405.209L21.1989 421.783C21.5831 423.394 22.5905 424.785 23.9997 425.652C25.4089 426.519 27.1044 426.79 28.7132 426.406Z" fill="#F6B089"/>
  <path d="M18.8489 423.274C20.4564 422.888 21.845 421.879 22.7096 420.469C23.5743 419.059 23.8441 417.362 23.4599 415.753L19.5115 399.144C19.1258 397.535 18.1177 396.145 16.7087 395.279C15.2997 394.414 13.6051 394.144 11.9972 394.528C10.3897 394.914 9.00102 395.924 8.1364 397.334C7.27178 398.744 7.00192 400.441 7.38614 402.05L11.3346 418.659C11.7202 420.268 12.7283 421.658 14.1373 422.524C15.5464 423.389 17.241 423.659 18.8489 423.274Z" fill="#F6B089"/>
  <path d="M231.244 296.487C230.886 294.878 229.906 293.476 228.518 292.588C227.131 291.7 225.448 291.399 223.839 291.749L207.178 295.414C205.57 295.774 204.169 296.756 203.282 298.146C202.395 299.536 202.094 301.221 202.444 302.833C202.803 304.443 203.784 305.845 205.173 306.732C206.562 307.62 208.245 307.922 209.856 307.572L226.51 303.906C228.118 303.547 229.519 302.564 230.406 301.174C231.293 299.784 231.594 298.099 231.244 296.487Z" fill="#F6B089"/>
  <path d="M226.592 306.977C226.234 305.367 225.254 303.965 223.867 303.076C222.48 302.186 220.797 301.883 219.187 302.231L202.526 305.903C200.919 306.263 199.52 307.244 198.633 308.633C197.746 310.021 197.444 311.704 197.792 313.315C198.151 314.925 199.132 316.328 200.521 317.217C201.909 318.106 203.593 318.409 205.204 318.061L221.858 314.389C223.467 314.032 224.868 313.052 225.756 311.662C226.643 310.273 226.944 308.588 226.592 306.977Z" fill="#F6B089"/>
  <path d="M8.96404 408.935C8.21944 406.644 -1.73359 367.389 2.37878 330.376C6.49115 293.363 25.5365 260.678 26.3426 259.311L44.5818 270.087L35.4622 264.699L44.5818 270.08C44.411 270.374 27.0257 300.283 23.4052 332.714C19.8324 365.289 29.0136 402.09 29.1707 402.61L8.96404 408.935Z" fill="#F6B089"/>
  <path d="M74.3728 220.329C71.2328 220.221 68.1237 219.671 65.137 218.695L65.0482 220.138C64.9356 221.46 65.0887 222.791 65.4984 224.053C65.9082 225.315 66.5663 226.482 67.434 227.485C68.3017 228.488 69.3613 229.307 70.5504 229.894C71.7394 230.48 73.0338 230.822 74.3572 230.9C75.6805 230.978 77.006 230.789 78.2554 230.346C79.5048 229.902 80.6527 229.213 81.6316 228.318C82.6104 227.423 83.4002 226.341 83.9545 225.136C84.5088 223.93 84.8162 222.626 84.8586 221.3L84.9884 219.126C81.5293 220.053 77.9514 220.458 74.3728 220.329Z" fill="#E05056"/>
  <defs>
  <clipPath id="clip0">
  <rect width="90" height="90" fill="white" transform="translate(991 64)"/>
  </clipPath>
  <clipPath id="clip1">
  <rect width="799" height="458.57" fill="white" transform="translate(172)"/>
  </clipPath>
  </defs>
  </svg>
);

EducatorHeroSVG.propTypes = {
  className: PropTypes.string,
  playButtonClassName: PropTypes.string,
  onClick: PropTypes.func,
};

const EducatorHeroMobileSVG = ({
  className,
  playButtonClassName,
  onClick,
}) => (
  <svg className={className} onClick={onClick} width="823" height="575" viewBox="0 0 823 575" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M444 206L381 242.373L381 169.627L444 206Z" fill="white"/>
  <g clipPath="url(#clip0)">
  <path d="M790.5 0H56.5H46.5V10V436V446H56.5H790.5H800.5V436V10V0H790.5Z" fill="#F6DDB7"/>
  <path d="M790.5 10H56.5V436H790.5V10Z" fill="#8F01AA"/>
  <path d="M808 458.57H39C35.0218 458.57 31.2064 456.989 28.3934 454.176C25.5804 451.363 24 447.548 24 443.57C24 439.592 25.5804 435.776 28.3934 432.963C31.2064 430.15 35.0218 428.57 39 428.57H808C811.978 428.57 815.794 430.15 818.607 432.963C821.42 435.776 823 439.592 823 443.57C823 447.548 821.42 451.363 818.607 454.176C815.794 456.989 811.978 458.57 808 458.57Z" fill="#F6DDB7"/>
  <path
    d="M399.5 220.265V158L455.426 189.133L399.5 220.265Z"
    fill="white"
    className={`animate__animated animate__bounce animate__delay-1s animate__repeat-1 ${playButtonClassName}`}
  />
  <path d="M395.198 253.213H387.926V270.013H391.814V265.381H395.198C399.686 265.381 402.494 263.053 402.494 259.309C402.494 255.541 399.686 253.213 395.198 253.213ZM394.982 262.213H391.814V256.381H394.982C397.358 256.381 398.558 257.461 398.558 259.309C398.558 261.133 397.358 262.213 394.982 262.213ZM407.67 270.013H419.982V266.845H411.558V253.213H407.67V270.013ZM437.247 270.013H441.327L433.815 253.213H429.975L422.487 270.013H426.471L427.959 266.413H435.759L437.247 270.013ZM429.207 263.461L431.871 257.029L434.535 263.461H429.207ZM459.031 253.213H455.215L450.727 260.677L446.239 253.213H442.111L448.615 264.013V270.013H452.503V264.061L459.031 253.213Z" fill="white"/>
  </g>
  <path d="M216.571 295.599C215.97 294.063 214.786 292.828 213.278 292.163C211.77 291.498 210.061 291.458 208.523 292.05L192.627 298.204C191.093 298.806 189.859 299.99 189.195 301.5C188.531 303.009 188.49 304.72 189.082 306.259C189.683 307.794 190.867 309.029 192.374 309.694C193.882 310.359 195.591 310.4 197.129 309.808L213.032 303.654C214.565 303.051 215.797 301.865 216.46 300.356C217.123 298.847 217.163 297.137 216.571 295.599Z" fill="#F2A183"/>
  <path d="M51.0527 346.916L17.0642 566.428L40.0162 569.988L74.0047 350.477L51.0527 346.916Z" fill="#F6B089"/>
  <path d="M105.974 569.158C105.68 470.414 72.2552 363.218 71.9204 362.144L94.0671 355.143C95.488 359.656 128.906 466.715 129.2 569.09L105.974 569.158Z" fill="#F6B089"/>
  <path d="M80.1792 574.02C79.9484 569.423 78.389 564.991 75.6904 561.264C72.9918 557.537 69.27 554.675 64.9772 553.024C60.6843 551.374 56.0051 551.007 51.5078 551.968C47.0105 552.929 42.8887 555.176 39.6429 558.437H16.0617V574.02H80.1792Z" fill="#232323"/>
  <path d="M166.703 574.02C166.682 569.081 165.19 564.262 162.419 560.176C159.647 556.091 155.722 552.925 151.144 551.083C146.567 549.242 141.544 548.808 136.719 549.838C131.894 550.867 127.485 553.314 124.056 556.864H100.83V574.02H166.703Z" fill="#232323"/>
  <path d="M66.8449 396.771L72.1117 362.766C71.9956 362.397 71.9341 362.185 71.9205 362.144L72.221 362.049L74.0108 350.493L51.058 346.93L44.425 389.824L66.8449 396.771Z" fill="#E87D6A"/>
  <path d="M81.1083 395.431L103.856 390.385C98.6234 369.769 94.5315 356.661 94.0534 355.143L72.1936 362.049L72.0843 362.767C72.9655 365.55 76.5314 377.482 81.1083 395.431Z" fill="#E87D6A"/>
  <path d="M167.639 323.265C149.195 323.265 133.606 318.854 121.105 310.047C96.014 292.385 93.6094 263.667 93.4933 262.484L114.608 260.891V260.829C114.608 261.027 116.583 281.083 133.531 292.871C149.359 303.88 173.418 305.042 205.033 296.338L210.648 316.769C194.927 321.104 180.591 323.269 167.639 323.265Z" fill="#F6B089"/>
  <path d="M124.104 269.096C124.791 264.47 124.119 259.745 122.168 255.495C120.218 251.246 117.074 247.656 113.121 245.165C109.167 242.674 104.574 241.388 99.903 241.465C95.2317 241.542 90.6839 242.979 86.8143 245.599C82.9447 248.219 79.9208 251.91 78.1114 256.222C76.302 260.533 75.7854 265.278 76.6247 269.879C77.4639 274.479 79.6227 278.735 82.8376 282.129C86.0526 285.522 90.1846 287.905 94.7297 288.987L124.104 269.096Z" fill="#52107C"/>
  <path d="M47.5809 271.729H89.3535C93.0316 271.729 96.6736 272.454 100.072 273.863C103.47 275.271 106.557 277.336 109.158 279.94C111.759 282.543 113.822 285.634 115.229 289.035C116.637 292.436 117.361 296.082 117.361 299.763V379.588H19.6003V299.736C19.6003 296.054 20.3248 292.409 21.7323 289.008C23.1398 285.606 25.2029 282.516 27.8036 279.912C30.4044 277.309 33.492 275.244 36.89 273.835C40.2881 272.426 43.9301 271.701 47.6082 271.701L47.5809 271.729Z" fill="#52107C"/>
  <path d="M67.4801 229.272H73.3344C80.7626 229.272 87.8865 232.226 93.1389 237.484C98.3914 242.741 101.342 249.872 101.342 257.307V285.342H39.4996V257.307C39.4996 249.877 42.4466 242.75 47.6932 237.493C52.9398 232.237 60.0567 229.28 67.4801 229.272Z" fill="#7B04A4"/>
  <path d="M107.276 160.562L36.7202 157.219C18.194 156.341 2.46475 170.663 1.58784 189.207C0.71093 207.751 15.0185 223.495 33.5446 224.373L104.1 227.716C122.626 228.594 138.355 214.272 139.232 195.728C140.109 177.184 125.802 161.44 107.276 160.562Z" fill="#001B2B"/>
  <path d="M139.221 195.733C138.814 204.358 135.106 212.495 128.866 218.457C122.626 224.419 114.334 227.748 105.707 227.755C105.175 227.755 104.635 227.741 104.088 227.714L33.536 224.37C28.8102 224.142 24.1858 222.918 19.9653 220.778C15.7447 218.637 12.0232 215.629 9.04406 211.95C6.06495 208.271 3.89543 204.004 2.67742 199.428C1.45941 194.852 1.22036 190.07 1.97592 185.395C3.22187 192.994 7.03573 199.935 12.7801 205.057C18.5244 210.18 25.8512 213.174 33.536 213.539L104.088 216.883C104.628 216.883 105.175 216.924 105.707 216.924C113.674 216.917 121.378 214.076 127.445 208.908C133.511 203.74 137.545 196.581 138.825 188.711C139.201 191.031 139.334 193.385 139.221 195.733Z" fill="black"/>
  <path d="M85.3631 214.422C85.6844 208.944 81.5086 204.243 76.0362 203.921C70.5638 203.6 65.8671 207.779 65.5458 213.257L64.3039 234.431C63.9826 239.909 68.1584 244.61 73.6308 244.932C79.1032 245.254 83.7999 241.074 84.1211 235.596L85.3631 214.422Z" fill="#E87D6A"/>
  <path d="M110.23 188.766C113.946 188.766 116.958 185.75 116.958 182.031C116.958 178.311 113.946 175.295 110.23 175.295C106.514 175.295 103.501 178.311 103.501 182.031C103.501 185.75 106.514 188.766 110.23 188.766Z" fill="#E87D6A"/>
  <path d="M42.758 186.215C46.4741 186.215 49.4867 183.199 49.4867 179.479C49.4867 175.76 46.4741 172.744 42.758 172.744C39.0418 172.744 36.0293 175.76 36.0293 179.479C36.0293 183.199 39.0418 186.215 42.758 186.215Z" fill="#E87D6A"/>
  <path d="M58.1334 144.004L96.6849 145.372C101.03 145.526 105.137 147.402 108.1 150.587C111.064 153.771 112.643 158.004 112.489 162.354L111.668 185.526C111.329 195.097 107.206 204.141 100.205 210.669C93.204 217.197 83.8992 220.674 74.3374 220.335C65.3858 220.017 56.9268 216.153 50.8215 209.592C44.7161 203.032 41.4643 194.313 41.7816 185.352L42.7382 158.339C42.8064 156.386 43.2591 154.464 44.0702 152.686C44.8813 150.907 46.0349 149.307 47.4649 147.975C48.8949 146.643 50.5734 145.607 52.404 144.926C54.2347 144.245 56.1817 143.931 58.1334 144.004Z" fill="#F6B089"/>
  <path d="M62.8417 168.204H62.8349C61.3409 168.204 60.1297 169.416 60.1297 170.912V175.432C60.1297 176.927 61.3409 178.139 62.8349 178.139H62.8417C64.3357 178.139 65.5469 176.927 65.5469 175.432V170.912C65.5469 169.416 64.3357 168.204 62.8417 168.204Z" fill="#070D13"/>
  <path d="M101.54 169.825H101.534C100.04 169.825 98.8284 171.037 98.8284 172.532V177.052C98.8284 178.548 100.04 179.76 101.534 179.76H101.54C103.034 179.76 104.246 178.548 104.246 177.052V172.532C104.246 171.037 103.034 169.825 101.54 169.825Z" fill="#070D13"/>
  <path d="M66.7697 166.413C67.6865 166.413 68.4297 165.669 68.4297 164.751C68.4297 163.833 67.6865 163.089 66.7697 163.089H60.1639C59.2471 163.089 58.504 163.833 58.504 164.751C58.504 165.669 59.2471 166.413 60.1639 166.413H66.7697Z" fill="#E87D6A"/>
  <path d="M105.516 167.999C106.433 167.999 107.176 167.256 107.176 166.338C107.176 165.42 106.433 164.676 105.516 164.676H98.9103C97.9935 164.676 97.2503 165.42 97.2503 166.338C97.2503 167.256 97.9935 167.999 98.9103 167.999H105.516Z" fill="#E87D6A"/>
  <path d="M42.6625 170.071C41.9793 188.732 57.6911 204.164 76.3265 204.821C84.1498 205.098 91.8507 202.82 98.2672 198.332C104.684 193.843 109.467 187.387 111.896 179.938L111.698 185.525C111.531 190.263 110.433 194.922 108.466 199.236C106.5 203.55 103.704 207.433 100.237 210.665C96.7714 213.897 92.7033 216.414 88.2654 218.072C83.8275 219.73 79.1068 220.497 74.3728 220.329C55.7305 219.645 40.9752 203.918 42.0272 185.292C42.2458 181.408 42.6625 170.071 42.6625 170.071Z" fill="#F2A183"/>
  <path d="M42.7717 158.365L42.6009 163.117L112.416 165.34L112.518 162.42C112.549 161.529 112.508 160.637 112.395 159.753L42.8195 157.51C42.799 157.784 42.7785 158.064 42.7717 158.365Z" fill="#E87D6A"/>
  <path d="M42.7717 158.365L42.601 163.117L112.416 165.34L112.518 162.42C112.55 161.529 112.508 160.637 112.395 159.753L42.8195 157.51C42.7991 157.784 42.7786 158.064 42.7717 158.365Z" fill="#E87D6A"/>
  <path d="M66.7697 163.609C67.6865 163.609 68.4297 162.865 68.4297 161.947C68.4297 161.03 67.6865 160.286 66.7697 160.286H60.1639C59.2471 160.286 58.504 161.03 58.504 161.947C58.504 162.865 59.2471 163.609 60.1639 163.609H66.7697Z" fill="#070D13"/>
  <path d="M105.516 165.196C106.433 165.196 107.176 164.452 107.176 163.534C107.176 162.616 106.433 161.873 105.516 161.873H98.9103C97.9935 161.873 97.2503 162.616 97.2503 163.534C97.2503 164.452 97.9935 165.196 98.9103 165.196H105.516Z" fill="#070D13"/>
  <path d="M78.9223 118.022C74.337 117.86 69.7649 118.604 65.467 120.211C61.1691 121.819 57.2297 124.258 53.8739 127.39C50.5181 130.522 47.8115 134.285 45.9089 138.464C44.0063 142.643 42.9449 147.156 42.7853 151.746L42.5804 157.517C42.5804 157.715 42.5804 157.913 42.5804 158.105L76.0532 159.295V147.075L89.5857 159.773L112.327 160.58C112.327 160.382 112.327 160.19 112.327 159.992L112.532 154.214C112.866 144.955 109.513 135.941 103.211 129.154C96.9089 122.368 88.1726 118.364 78.9223 118.022Z" fill="#001B2B"/>
  <path d="M90 160H76V147L90 160Z" fill="#E87D6A"/>
  <path d="M94.0738 187.768C94.0663 188.111 94.0412 188.453 93.9987 188.793C93.582 192.616 91.7922 195.747 87.1675 197.087C85.2925 197.588 83.3557 197.819 81.4157 197.771C79.4042 197.831 77.3977 197.539 75.4862 196.91C71.8178 195.59 70.5404 192.807 70.1989 189.388C70.1442 188.862 70.1169 188.315 70.1032 187.754L94.0738 187.768Z" fill="#831221"/>
  <path d="M87.1676 197.067C85.2926 197.567 83.3558 197.798 81.4157 197.75C79.4043 197.81 77.3978 197.518 75.4863 196.889C76.2088 196.038 77.11 195.357 78.1257 194.894C79.1414 194.432 80.2466 194.2 81.3623 194.214C82.478 194.228 83.5768 194.489 84.5803 194.977C85.5838 195.466 86.4672 196.17 87.1676 197.039V197.067Z" fill="#EA3559"/>
  <path d="M70.1032 187.767C70.1032 188.328 70.1442 188.875 70.1989 189.401C73.6688 191.424 77.6264 192.455 81.6411 192.383C86.539 192.383 90.9452 191.015 93.9987 188.793C94.0412 188.452 94.0663 188.11 94.0738 187.767H70.1032Z" fill="white"/>
  <path d="M50.2724 278.539C54.2545 276.002 57.4035 272.348 59.3276 268.034C61.2517 263.719 61.8661 258.933 61.0943 254.272C60.3225 249.61 58.1985 245.279 54.9867 241.816C51.7748 238.354 47.6168 235.912 43.0298 234.797C38.4428 233.681 33.6293 233.94 29.1881 235.541C24.7469 237.142 20.8739 240.014 18.051 243.802C15.2281 247.589 13.5798 252.123 13.3111 256.84C13.0424 261.558 14.1653 266.25 16.54 270.334L50.2724 278.539Z" fill="#52107C"/>
  <path d="M30.2501 412.306C31.0459 412.115 31.7963 411.769 32.4584 411.287C33.1205 410.806 33.6814 410.199 34.109 409.5C34.5366 408.802 34.8224 408.026 34.9503 407.217C35.0782 406.407 35.0456 405.581 34.8543 404.785L30.9059 388.176C30.7157 387.378 30.3704 386.626 29.8898 385.963C29.4091 385.299 28.8025 384.737 28.1046 384.309C27.4067 383.88 26.6312 383.593 25.8225 383.465C25.0138 383.336 24.1878 383.369 23.3916 383.56C22.5958 383.751 21.8454 384.097 21.1832 384.579C20.5211 385.06 19.9603 385.668 19.5327 386.366C19.1051 387.065 18.8192 387.84 18.6913 388.65C18.5635 389.459 18.5961 390.285 18.7874 391.082L22.7358 407.691C22.9259 408.488 23.2712 409.24 23.7519 409.903C24.2326 410.567 24.8392 411.129 25.5371 411.558C26.235 411.986 27.0105 412.273 27.8191 412.401C28.6278 412.53 29.4539 412.497 30.2501 412.306Z" fill="#F2A183"/>
  <path d="M28.7132 426.406C30.3197 426.018 31.7072 425.009 32.5715 423.599C33.4359 422.189 33.7065 420.493 33.3242 418.884L29.3963 402.296C29.0091 400.688 28.0004 399.299 26.5915 398.435C25.1827 397.571 23.4889 397.302 21.882 397.687C20.2745 398.073 18.8858 399.082 18.0212 400.492C17.1566 401.903 16.8867 403.599 17.2709 405.209L21.1989 421.783C21.583 423.394 22.5905 424.785 23.9997 425.652C25.4089 426.519 27.1043 426.79 28.7132 426.406Z" fill="#F6B089"/>
  <path d="M18.8489 423.274C20.4564 422.888 21.845 421.879 22.7096 420.469C23.5743 419.059 23.8441 417.362 23.4599 415.753L19.5115 399.144C19.1258 397.535 18.1177 396.145 16.7087 395.279C15.2997 394.414 13.6051 394.144 11.9972 394.528C10.3897 394.914 9.00102 395.924 8.1364 397.334C7.27178 398.744 7.00192 400.441 7.38614 402.05L11.3346 418.659C11.7202 420.268 12.7283 421.658 14.1373 422.524C15.5464 423.389 17.241 423.659 18.8489 423.274Z" fill="#F6B089"/>
  <path d="M231.244 296.487C230.886 294.878 229.906 293.476 228.518 292.588C227.131 291.7 225.448 291.399 223.839 291.749L207.178 295.414C205.57 295.774 204.169 296.756 203.282 298.146C202.395 299.536 202.094 301.221 202.444 302.833C202.803 304.443 203.784 305.845 205.173 306.732C206.562 307.62 208.245 307.922 209.856 307.572L226.51 303.906C228.118 303.547 229.519 302.564 230.406 301.174C231.293 299.784 231.594 298.099 231.244 296.487Z" fill="#F6B089"/>
  <path d="M226.592 306.977C226.234 305.367 225.254 303.965 223.867 303.076C222.48 302.186 220.797 301.883 219.187 302.231L202.526 305.903C200.919 306.263 199.52 307.244 198.633 308.633C197.746 310.021 197.444 311.704 197.792 313.315C198.151 314.925 199.132 316.328 200.521 317.217C201.909 318.106 203.593 318.409 205.204 318.061L221.858 314.389C223.467 314.032 224.868 313.052 225.756 311.662C226.643 310.273 226.944 308.588 226.592 306.977Z" fill="#F6B089"/>
  <path d="M8.96407 408.935C8.21947 406.644 -1.73356 367.389 2.37881 330.376C6.49118 293.363 25.5365 260.678 26.3426 259.311L44.5818 270.087L35.4622 264.699L44.5818 270.08C44.4111 270.374 27.0257 300.283 23.4052 332.714C19.8325 365.289 29.0136 402.09 29.1707 402.61L8.96407 408.935Z" fill="#F6B089"/>
  <path d="M74.3728 220.329C71.2328 220.221 68.1237 219.671 65.137 218.695L65.0482 220.138C64.9356 221.46 65.0886 222.791 65.4984 224.053C65.9082 225.315 66.5663 226.482 67.434 227.485C68.3017 228.488 69.3613 229.307 70.5504 229.894C71.7394 230.48 73.0338 230.822 74.3571 230.9C75.6805 230.978 77.006 230.789 78.2553 230.346C79.5047 229.902 80.6527 229.213 81.6315 228.318C82.6104 227.423 83.4002 226.341 83.9545 225.136C84.5087 223.93 84.8162 222.626 84.8586 221.3L84.9884 219.126C81.5293 220.053 77.9514 220.458 74.3728 220.329Z" fill="#E05056"/>
  <defs>
  <clipPath id="clip0">
  <rect width="799" height="458.57" fill="white" transform="translate(24)"/>
  </clipPath>
  </defs>
  </svg>
);

EducatorHeroMobileSVG.propTypes = {
  className: PropTypes.string,
  playButtonClassName: PropTypes.string,
  onClick: PropTypes.func,
};

export const Hero = ({
  hero,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const { title, title2, subtitle } = hero;
  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          scrollSnapAlign: 'start',
        },
      })}
    >
      <Navbar/>
      <Section
        sx={(theme) => ({
          paddingBottom: theme.spacing(8),
          textAlign: 'center',
          '& .educatorHero': {
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
            maxHeight: '500px',
            maxWidth: '100%',
            marginTop: '3rem',
            marginBottom: '5rem',
          },
          '& .educatorHeroPlayButton': {
            cursor: 'pointer',
            '&:hover': {
              fill: '#ccc',
            },
          },
          '& .educatorHeroMobile': {
            [theme.breakpoints.up('md')]: {
              display: 'none',
            },
            maxHeight: '300px',
            maxWidth: '100%',
            marginTop: '2rem',
          },
        })}
      >
        <Typography
          variant="h4"
          component="h4"
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              fontSize: '20px',
              lineHeight: '32px',
            },
            fontWeight: 'normal',
            fontSize: '12px',
            lineHeight: '24px',
            marginTop: '4rem',
            color: '#AD3DAA',
          })}
        >
          {subtitle}
        </Typography>
        <Typography
          variant="h1"
          component="h1"
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              fontSize: '44px',
              lineHeight: '56px',
              marginTop: '2rem',
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: '950px',
            },
            fontWeight: 'bold',
            fontSize: '26px',
            lineHeight: '32px',
            marginTop: '1rem',
          })}
        >
          {title}
        </Typography>
        {
          title2
          && <Typography
            variant="h2"
            component="h2"
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: {
                fontSize: '24px',
                lineHeight: '32px',
              },
              fontWeight: 'normal',
              fontSize: '16px',
              lineHeight: '24px',
              marginTop: '1rem',
            })}
          >
            {title2}
          </Typography>
        }
        <EducatorHeroSVG
          className={'educatorHero'}
          playButtonClassName={'educatorHeroPlayButton'}
          onClick={() => setIsVideoOpen(true)}
        />
        <EducatorHeroMobileSVG
          className={'educatorHeroMobile'}
          playButtonClassName={'educatorHeroPlayButton'}
          onClick={() => setIsVideoOpen(true)}
        />
        <ResponsiveDialog
          open={isVideoOpen}
          handleClose={() => setIsVideoOpen(false)}
          handleSubmit={() => { window.location.href = `${config.APP_URL}/?action=signUp`; }}
          submitText={'Get Started'}
          maxWidth="md"
        >
          <ReactPlayer
            url='https://www.youtube.com/watch?v=CbF-x36_H_w'
            width='100%'
            height={fullScreen ? '100%' : '75vh'}
            controls
            playing={isVideoOpen}
          />
        </ResponsiveDialog>
      </Section>
    </Box>
  );
};

Hero.propTypes = {
  hero: PropTypes.object,
};

Hero.defaultProps = {
  hero: {},
};

export default Hero;
