import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Common from './Common';
import EducatorHero from '../components/Hero/Educators';
import Footer from '../components/Footer/Footer';
import '../../i18n';

const TemplateWrapper = ({ hero, children, disableNavbar }) => (
    <Common>
      <EducatorHero hero={hero} />
      <div>{children}</div>
      {!disableNavbar
      && <Box
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            scrollSnapAlign: 'start',
          },
        })}
      >
        <Footer />
      </Box>}
    </Common>
);

TemplateWrapper.propTypes = {
  children: PropTypes.node,
  disableNavbar: PropTypes.bool,
  hero: PropTypes.object,
};

export default TemplateWrapper;
