/* eslint-disable camelcase */
import de from './educators.de.yaml';
import en from './educators.en.yaml';
import en_US from './educators.en_US.yaml';
import es from './educators.es.yaml';
import ga from './educators.ga.yaml';
import it from './educators.it.yaml';
import pt from './educators.pt.yaml';
import fr from './educators.fr.yaml';

export default {
  de,
  en,
  en_US,
  es,
  ga,
  it,
  pt,
  fr,
};
