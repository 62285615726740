import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { styled } from '@mui/material/styles';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DefaultButton from '@fathomtech/nualang-ui-components/dist/Misc/DefaultColourButton';
import Layout from '../layouts/Educators';
import content from '../../content/educators/index';
import useContentLanguageSwitcher from '../hooks/useContentLanguageSwitcher';

import { HTMLContent } from '../components/Content';
import Section from '../components/Section';

import NualaPhone from '../../static/img/nuala-phone.svg';
import NualasLineUp from '../../static/img/nualas.svg';
import NualasLineUpMobile from '../../static/img/nualas-mobile.svg';
import Pronunciation from '../../static/img/pronunciation.svg';
import Customize from '../../static/img/open-laptop.svg';
import Progress from '../../static/img/progress.svg';
import World from '../../static/img/teacher-world.svg';
import TeacherCreate from '../../static/img/teacher-create.svg';
import TeacherCreateMobile from '../../static/img/teacher-create-mobile.svg';
import JoinNuala from '../../static/img/join-nuala.svg';

const howItWorksSection = (theme) => ({
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(8),
  },
  marginBottom: theme.spacing(8),
  textAlign: 'center',
});

const section = (theme) => ({
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(8),
  },
  paddingBottom: theme.spacing(8),
  textAlign: 'center',
});

const Snap = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    scrollSnapAlign: 'center',
    minHeight: '100vh',
  },
}));

const InfoBlock = styled(Grid)(({ theme, textAlign = 'left' }) => ({
  [theme.breakpoints.up('md')]: {
    textAlign,
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  textAlign: 'center',
  '& .infoBlockTitle': {
    [theme.breakpoints.up('md')]: {
      fontSize: '44px',
      lineHeight: '56px',
      marginTop: '3rem',
      marginBottom: '3rem',
      maxWidth: 400,
    },
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '40px',
    marginTop: '1rem',
    marginBottom: '2rem',
  },
  '& .infoBlockContent': {
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      lineHeight: '180%',
      maxWidth: 400,
    },
    display: 'inline-block',
    fontSize: '16px',
    lineHeight: '150%',
    color: '#4B5D68',
    marginBottom: '2rem',
  },
  '& .marginLeft': {
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
    },
  },
  '& .shareCta': {
    marginBottom: '2rem',
  },
  '& .mobileImage': {
    [theme.breakpoints.down('md')]: {
      display: 'block',
      maxHeight: '300px',
      marginBottom: '2rem',
    },
    display: 'none',
    margin: 'auto',
  },
}));

const JoinUsInfoBlock = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    textAlign: 'right',
  },
  textAlign: 'center',
  '& .joinTitle': {
    [theme.breakpoints.up('md')]: {
      fontSize: '44px',
      lineHeight: '56px',
      marginTop: '4rem',
      marginBottom: '2rem',
      textAlign: 'left',
    },
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '40px',
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  '& .joinContent': {
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      lineHeight: '180%',
      textAlign: 'left',
      marginBottom: '4rem',
    },
    display: 'inline-block',
    fontSize: '16px',
    lineHeight: '150%',
    color: '#4B5D68',
    marginBottom: '2rem',
    maxWidth: 500,
  },
  '& .joinNuala': {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    position: 'absolute',
    left: '-50px',
    maxHeight: '200px',
    bottom: 0,
  },
  '& .joinCta': {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
    textAlign: 'center',
    marginBottom: '2rem',
  },
  '& .mobileImage': {
    [theme.breakpoints.down('md')]: {
      display: 'block',
      maxHeight: '300px',
      marginBottom: '2rem',
    },
    display: 'none',
    margin: 'auto',
  },
}));

const NualaPhoneImg = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  [theme.breakpoints.down('lg')]: {
    maxHeight: '475px',
    left: 10,
  },
  position: 'absolute',
  bottom: 0,
  left: 50,
  height: '120%',
  maxHeight: '625px',
}));

const NualaPhoneMobileImg = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'block',
    maxHeight: '230px',
  },
  display: 'none',
  marginLeft: 'auto',
  marginRight: 'auto',
}));

const HowItWorks = ({
  howItWorks,
}) => (
    <Section
      sx={howItWorksSection}
    >
      <NualaPhoneImg src={NualaPhone} alt="Nuala Phone" />
      <Grid
        container
        justifyContent="center"
        sx={(theme) => ({
          background: 'rgba(240, 99, 184, 0.15)',
          borderRadius: '50px',
          padding: theme.spacing(3, 3, 0, 3),
        })}
      >
        <Grid item xs={0} md={5}>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              textAlign: 'left',
              paddingLeft: theme.spacing(10),
              paddingBottom: theme.spacing(6),
            },
            textAlign: 'center',
            '& .howItWorksTitle': {
              [theme.breakpoints.up('md')]: {
                fontSize: '44px',
                lineHeight: '56px',
                marginTop: '4rem',
                marginBottom: '2rem',
                marginRight: '0px',
                textAlign: 'left',
              },
              fontWeight: 'bold',
              fontSize: '26px',
              lineHeight: '32px',
              marginTop: '2rem',
              marginBottom: '2rem',
              textAlign: 'center',
            },
            '& .flyer': {
              marginTop: '2rem',
              textTransform: 'none',
            },
          })}
        >
          <Box>
            <HTMLContent
              content={howItWorks.title}
              className={'howItWorksTitle'}
            />
            <Box
              sx={(theme) => ({
                [theme.breakpoints.up('md')]: {
                  fontSize: '16px',
                  lineHeight: '180%',
                  marginBottom: '2rem',
                  textAlign: 'left',
                },
                display: 'inline-block',
                fontSize: '16px',
                lineHeight: '150%',
                color: '#4B5D68',
                marginBottom: '2rem',
                maxWidth: 450,
                textAlign: 'center',
              })}
            >
              <HTMLContent
                content={howItWorks.content}
              />
              <DefaultButton
                component="a"
                href={howItWorks.flyer.href}
                download={howItWorks.flyer.download}
                className={'flyer'}
                variant="contained"
                startIcon={<PictureAsPdfIcon />}
              >
                {howItWorks.flyer.label}
              </DefaultButton>
            </Box>
            <NualaPhoneMobileImg src={NualaPhone} alt="Nuala Phone" />
          </Box>
        </Grid>
      </Grid>
    </Section>
);

HowItWorks.propTypes = {
  howItWorks: PropTypes.object,
};

const Nualas = ({
  nualas,
}) => (
    <React.Fragment>
      <Section
        sx={(theme) => ({
          textAlign: 'center',
          '& .nualasTitle': {
            [theme.breakpoints.up('md')]: {
              fontSize: '44px',
              lineHeight: '56px',
              marginTop: '3rem',
              marginBottom: '3rem',
            },
            [theme.breakpoints.down('md')]: {
              paddingTop: theme.spacing(8),
            },
            fontWeight: 'bold',
            fontSize: '26px',
            lineHeight: '32px',
            marginTop: '1rem',
            marginBottom: '2rem',
            textAlign: 'center',
          },
          '& .nualasContent': {
            [theme.breakpoints.up('md')]: {
              fontSize: '16px',
              lineHeight: '180%',
            },
            display: 'inline-block',
            fontSize: '16px',
            lineHeight: '150%',
            color: '#4B5D68',
            marginBottom: '2rem',
            maxWidth: 800,
            textAlign: 'center',
          },
          '& .nualasHero': {
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
            maxHeight: '500px',
            textAlign: 'center',
            marginBottom: '6rem',
          },
        })}
      >
        <HTMLContent
          content={nualas.title}
          className={'nualasTitle'}
        />
        <HTMLContent
          content={nualas.content}
          className={'nualasContent'}
        />
        <img
          src={NualasLineUp}
          alt="NualasLineUp"
          className={'nualasHero'}
        />
      </Section>
      <Box
        sx={(theme) => ({
          '& .nualasHeroMobile': {
            [theme.breakpoints.up('md')]: {
              display: 'none',
            },
            width: '100%',
            marginBottom: '6rem',
          },
        })}
      >
        <img
          src={NualasLineUpMobile}
          alt="NualasLineUp"
          className={'nualasHeroMobile'}
        />
      </Box>
    </React.Fragment>
);

Nualas.propTypes = {
  nualas: PropTypes.object,
};

const PronunciationPractice = ({
  pronunciation,
}) => (
    <Section
      sx={section}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <InfoBlock item xs={12} md={6} textAlign="right">
          <Box>
            <HTMLContent
              content={pronunciation.title}
              className={'infoBlockTitle marginLeft'}
            />
            <HTMLContent
              content={pronunciation.content}
              className="infoBlockContent"
            />
            <Box className="shareCta">
              <Link to="/contact/?newsAndUpdates=true">
                <Button variant="outlined" color="primary">Learn More</Button>
              </Link>
            </Box>
            <img src={Pronunciation} alt="Pronunciation" className="mobileImage" />
          </Box>
        </InfoBlock>
        <Grid
          item
          xs={0}
          md={6}
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              maxHeight: '380px !important',
            },
            backgroundImage: `url(${Pronunciation})`,
          })}
          className="backgroundImageStyle"
        >
        </Grid>
      </Grid>
    </Section>
);

PronunciationPractice.propTypes = {
  pronunciation: PropTypes.object,
};

const CustomizeContent = ({
  customize,
}) => (
    <Section
      sx={section}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={0}
          md={6}
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              maxHeight: '300px !important',
            },
            backgroundImage: `url(${Customize})`,
          })}
          className="backgroundImageStyle"
        >
        </Grid>
        <InfoBlock item xs={12} md={6}>
          <Box>
            <HTMLContent
              content={customize.title}
              className="infoBlockTitle"
            />
            <HTMLContent
              content={customize.content}
              className="infoBlockContent"
            />
            <Box className="shareCta">
              <Link to="/demo">
                <Button variant="outlined" color="primary">Get a Demo</Button>
              </Link>
            </Box>
            <img src={Customize} alt="Customize" className="mobileImage" />
          </Box>
        </InfoBlock>
      </Grid>
    </Section>
);

CustomizeContent.propTypes = {
  customize: PropTypes.object,
};

const StudentProgress = ({
  progress,
}) => (
    <Section
      sx={section}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <InfoBlock item xs={12} md={6} textAlign="right">
          <Box>
            <HTMLContent
              content={progress.title}
              className={'infoBlockTitle marginLeft'}
            />
            <HTMLContent
              content={progress.content}
              className="infoBlockContent"
            />
            {/* <Box className="shareCta">
              <Link to="/app?action=signUp">
                <Button variant="outlined" color="primary">Start Free Trial</Button>
              </Link>
            </Box> */}
             <img src={Progress} alt="Progress" className="mobileImage" />
          </Box>
        </InfoBlock>
        <Grid
          item
          xs={0}
          md={6}
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              maxHeight: '350px !important',
            },
            backgroundImage: `url(${Progress})`,
          })}
          className="backgroundImageStyle"
        >
        </Grid>
      </Grid>
    </Section>
);

StudentProgress.propTypes = {
  progress: PropTypes.object,
};

const ShareLessons = ({
  share,
}) => (
    <Section
      sx={section}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={0}
          md={6}
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              maxHeight: '480px !important',
            },
            backgroundImage: `url(${World})`,
            height: '60vh !important',
          })}
          className="backgroundImageStyle"
        >
        </Grid>
        <InfoBlock item xs={12} md={6}>
          <Box>
            <HTMLContent
              content={share.title}
              className="infoBlockTitle"
            />
            <HTMLContent
              content={share.content}
              className="infoBlockContent"
            />
            {/* <Box className="shareCta">
              <Link to="/app?action=signUp">
                <Button variant="outlined" color="primary">Start sharing</Button>
              </Link>
            </Box> */}
            <img src={World} alt="World" className="mobileImage" />
          </Box>
        </InfoBlock>
      </Grid>
    </Section>
);

ShareLessons.propTypes = {
  share: PropTypes.object,
};

const JoinUs = ({
  join,
}) => (
    <Section
      sx={section}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={(theme) => ({
          background: 'rgba(229, 229, 229, 0.37)',
          borderRadius: '50px',
          padding: theme.spacing(3, 3, 3, 3),
        })}
      >
        <JoinUsInfoBlock item xs={12} md={6}>
          <img src={JoinNuala} alt="Teacher" className="joinNuala" />
          <Box maxWidth={380} display="inline-block">
            <HTMLContent
              content={join.title}
              className="joinTitle"
            />
            <HTMLContent
              content={join.content}
              className="joinContent"
            />
            <br />
            <Box className="joinCta">
              <Link to="/pricing">
                <Button variant="contained" color="primary" size="large">{join.cta}</Button>
              </Link>
            </Box>
            <img src={TeacherCreateMobile} alt="Teacher" className="mobileImage" />
          </Box>
        </JoinUsInfoBlock>
        <Grid
          item
          xs={0}
          md={6}
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              maxHeight: '400px !important',
            },
            backgroundImage: `url(${TeacherCreate})`,
          })}
          className="backgroundImageStyle"
        >
        </Grid>
      </Grid>
    </Section>
);

JoinUs.propTypes = {
  join: PropTypes.object,
};

const EducatorsPageTemplate = ({
  landingPageData, helmet,
}) => {
  const {
    howItWorks,
    nualas,
    pronunciation,
    customize,
    progress,
    share,
    join,
  } = landingPageData;
  return (
    <Box
      sx={(theme) => ({
        '& .backgroundImageStyle': {
          [theme.breakpoints.up('md')]: {
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
          },
          height: 'calc(50vh)',
        },
      })}
    >
      {helmet || ''}
      <Snap>
        <HowItWorks
          howItWorks={howItWorks}
        />
      </Snap>
      <Snap>
        <Fade bottom>
          <Nualas
            nualas={nualas}
          />
        </Fade>
      </Snap>
      <Snap>
        <Fade bottom>
          <PronunciationPractice
            pronunciation={pronunciation}
          />
        </Fade>
      </Snap>
      <Snap>
        <Fade bottom>
          <CustomizeContent
            customize={customize}
          />
        </Fade>
      </Snap>
      <Snap>
        <Fade bottom>
          <StudentProgress
            progress={progress}
          />
        </Fade>
      </Snap>
      <Snap>
        <Fade bottom>
          <ShareLessons
            share={share}
          />
        </Fade>
      </Snap>
      <Snap>
        <Fade bottom>
          <JoinUs
            join={join}
          />
        </Fade>
      </Snap>
    </Box>
  );
};

EducatorsPageTemplate.propTypes = {
  landingPageData: PropTypes.object,
  helmet: PropTypes.object,
};

const EducatorsPage = ({ data }) => {
  const { frontmatter } = data.mdx;
  const [landingPageData] = useContentLanguageSwitcher({ content });
  return (
    <Layout
      contentComponent={HTMLContent}
      hero={landingPageData.hero}
    >
      <EducatorsPageTemplate
        helmet={
          <Helmet>
            <title>{`${frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${frontmatter.description}`}
            />
          </Helmet>
        }
        landingPageData={landingPageData}
      />
    </Layout>
  );
};

EducatorsPage.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default EducatorsPage;

export const pageQuery = graphql`
  query EducatorsPageTemplate {
    mdx(frontmatter: { templateKey: { eq: "educators-page" } }) {
      frontmatter {
        title
        description
        hero {
          title
          subtitle
          cta
        }
        howItWorks {
          title
          content
          flyer {
            href
            download
            label
          }
        }
        nualas {
          title
          content
        }
        pronunciation {
          title
          content
        }
        customize {
          title
          content
        }
        progress {
          title
          content
        }
        share {
          title
          content
        }
        join {
          title
          content
          cta
        }
      }
    }
  }
`;
